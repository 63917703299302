import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, message, Row, Spin, Select } from "antd";
import { useAuthContext } from "../../context/AuthContext";
import { API } from "../../constant";
import { getToken } from "../../helpers";

const { Option } = Select;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [occupations, setOccupations] = useState([]);
  const [codeCountries, setCodeCountries] = useState([]); 
  const { user, isLoading, setUser } = useAuthContext();

  useEffect(() => {
    const fetchOccupations = async () => {
      try {
        const response = await fetch(`${API}/Occupations`);
        const data = await response.json();
        
        if (data && Array.isArray(data)) {
          setOccupations(data);
        } else if (data && Array.isArray(data.data)) { 
          setOccupations(data.data);
        }
      } catch (error) {
        console.error("Error fetching occupations:", error);
      }
    };

    const fetchCodeCountries = async () => { 
      try {
        const response = await fetch(`${API}/code-countries`);
        const data = await response.json();
        
        if (data && Array.isArray(data)) {
          setCodeCountries(data);
        } else if (data && Array.isArray(data.data)) { 
          setCodeCountries(data.data);
        }
      } catch (error) {
        console.error("Error fetching code countries:", error);
      }
    };

    fetchOccupations();
    fetchCodeCountries();
  }, []);

  const handleProfileUpdate = async (data) => {
    setLoading(true);
    try {
      const response = await fetch(`${API}/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      setUser(responseData);
      message.success("Data saved successfully!");
    } catch (error) {
      console.error(Error);
      message.error("Error While Updating the Profile!");
    } finally {
      setLoading(false);
    }
  };

  if (isLoading || !user) {
    return <Spin size="large" />;
  }

  return (
    <Card className="profile_page_card">
      <Form
        layout="vertical"
        initialValues={{
          username: user?.username,
          email: user?.email,
          name: user?.name,
          lastname: user?.lastname,
          code_country: codeCountries.length > 0 ? user.code_country : undefined,
          company: user?.company,
          occupation: occupations.length > 0 ? user.occupation : undefined,
          phone: user?.phone,
          about: user?.about,
        }}
        onFinish={handleProfileUpdate}
      >
        <Row gutter={[16, 16]}>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Username es requerido!",
                  type: "string",
                },
              ]}
            >
              <Input placeholder="Username" disabled />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required!",
                  type: "email",
                },
              ]}
            >
              <Input placeholder="Email" disabled />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="A qué se dedica tu empresa"
              name="about"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input.TextArea placeholder="About" rows={3} />
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Nombres"
              name="name"
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <Input placeholder="name" />
            </Form.Item>
          </Col>
          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Apellidos"
              name="lastname"
              rules={[
                {
                  type: "string",
                  required: true,
                },
              ]}
            >
              <Input placeholder="lastname" />
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Código de país para tu teléfono"
              name="code_country"
            >
              <Select placeholder="Selecciona tu código de país">
                {Array.isArray(codeCountries) && codeCountries.map((codeCountry) => (
                  <Option key={codeCountry.id} value={codeCountry.id}>
                    {codeCountry.attributes.value} 
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Telefono sin el código"
              name="phone"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input placeholder="phone" />
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Ocupación"
              name="occupation"
            >
              <Select placeholder="Selecciona tu ocupación">
                {Array.isArray(occupations) && occupations.map((occupation) => (
                  <Option key={occupation.id} value={occupation.id}>
                    {occupation.attributes.value} 
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col md={8} lg={8} sm={24} xs={24}>
            <Form.Item
              label="Compañia"
              name="company"
              rules={[
                {
                  type: "string",
                },
              ]}
            >
              <Input placeholder="company" />
            </Form.Item>
          </Col>
        </Row>
        <Button
          className="profile_save_btn"
          htmlType="submit"
          type="primary"
          size="large"
        >
          {loading ? (
            <>
              <Spin size="small" /> Saving
            </>
          ) : (
            "Guardar Cambios"
          )}
        </Button>
      </Form>
    </Card>
  );
};

export default Profile;
