import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Space, Typography, message, FloatButton } from 'antd';
import { useAuthContext } from "../../context/AuthContext";
import OpenAI from 'openai';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { API } from "../../constant";
import { getToken } from "../../helpers";

const { TextArea } = Input;
const { Title } = Typography;

const Home = () => {
  const OPENAI_KEY = process.env.REACT_APP_OPENAI_KEY;
  const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
  const { user } = useAuthContext();
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]); // Estado para almacenar el historial de mensajes
  const [threadId, setThreadId] = useState(null); // Estado para almacenar el threadId
  const messagesEndRef = useRef(null); // Referencia para el scroll
  const openai = new OpenAI({ apiKey: OPENAI_KEY, dangerouslyAllowBrowser: true });

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  // Función para guardar los datos del thread en Strapi
  const saveThreadInStrapi = async (threadId, created_at,user_id) => {
    try {
      //console.log(threadId);
      //console.log(created_at);
      //console.log(user_id);
      //console.log( `Bearer ${getToken()}`);
      const response = await fetch(`${API}/open-ai-threads`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization:  `Bearer ${getToken()}`, // Token de autenticación si es necesario
        },
        body: JSON.stringify({
          data: {
            id_value: threadId,
            createdat: created_at,
            users_permissions_user: user_id,
          },
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        console.error('Error al guardar en Strapi:', error);
        throw new Error('No se pudo guardar el thread en Strapi');
      }

      const result = await response.json();
      //console.log('Thread guardado en Strapi:', result);
    } catch (error) {
      message.error(`Error al guardar en Strapi: ${error.message}`);
    }
  };

  const handleSendMessage = async () => {
    setIsLoading(true);
    try {
      let newHistory = [...history, { role: 'user', content: input }];
      
      if (threadId === null) {
        // Iniciar la conversación
        const initRes = await openai.beta.threads.createAndRun({
          assistant_id: ASSISTANT_ID,
          thread: {
            messages: [
              { role: "user", content: input },
            ],
          },
        });
        const newThreadId = initRes.thread_id;
        const createdAt = initRes.created_at; // Obtenemos la fecha de creación
        setThreadId(newThreadId); // Actualizar el estado con el nuevo threadId
        console.log(newThreadId);

        // Guardar el thread en Strapi
        await saveThreadInStrapi(newThreadId, createdAt, user.id);

        // Verificar el estado del thread y obtener la respuesta
        let responseReceived = false;
        while (!responseReceived) {
          const statusRes = await fetch(`https://api.openai.com/v1/threads/${newThreadId}/runs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${OPENAI_KEY}`,
              'OpenAI-Beta': 'assistants=v2',
            },
          });
          if (!statusRes.ok) {
            const error = await statusRes.json();
            console.error('API error response:', error);
            throw new Error(error.message || 'API request failed');
          }

          const statusData = await statusRes.json();
          console.log(statusData.data[0].id + " " + statusData.data[0].status);
          if (statusData.data[0].status === "completed") {
            let dataReceived = false;
            while (!dataReceived) {
              const threadMessages = await openai.beta.threads.messages.list(newThreadId);
              const lastMessageForRun = threadMessages.data[0].content[0].text.value;
              if (lastMessageForRun) {
                setResponse(lastMessageForRun.trim());
                newHistory = [...newHistory, { role: 'assistant', content: lastMessageForRun.trim() }];
                responseReceived = true;
                dataReceived = true;
              } else {
                message.error('No response from the assistant.');
                responseReceived = true;
                dataReceived = true;
              }
            }
          } else if (statusData.data[0].status === 'failed') {
            message.error('Assistant request failed.');
            responseReceived = true;
          } else {
            await new Promise(resolve => setTimeout(resolve, 500));
          }
        }
      } else {
        // Continuar conversación existente
        const NewMessage = await openai.beta.threads.messages.create(
          threadId,
          { role: "user", content: input }
        );
        
        const NewMessagerun = await openai.beta.threads.runs.create(
          threadId,
          { assistant_id: ASSISTANT_ID }
        );
        console.log(threadId);
        let responseReceived = false;
        while (!responseReceived) {
          const NewMessagestatusRes = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${OPENAI_KEY}`,
              'OpenAI-Beta': 'assistants=v2',
            },
          });
          if (!NewMessagestatusRes.ok) {
            const error = await NewMessagestatusRes.json();
            console.error('API error response:', error);
            throw new Error(error.message || 'API request failed');
          }
          const NewMessagestatusData = await NewMessagestatusRes.json();
          console.log(NewMessagestatusData.data[0].id + " " + NewMessagestatusData.data[0].status);
          if (NewMessagestatusData.data[0].status === "completed") {
            let NewMessagedataReceived = false;
            while (!NewMessagedataReceived) {
              const NewMessagethreadMessages = await openai.beta.threads.messages.list(threadId);
              console.log(NewMessagethreadMessages);
              const lastMessageForRun = NewMessagethreadMessages.data[0].content[0].text.value;
              if (lastMessageForRun) {
                setResponse(lastMessageForRun.trim());
                newHistory = [...newHistory, { role: 'assistant', content: lastMessageForRun.trim() }];
                responseReceived = true;
                NewMessagedataReceived = true;
              } else {
                message.error('No response from the assistant.');
                responseReceived = true;
                NewMessagedataReceived = true;
              }
            }
          } else if (NewMessagestatusData.data[0].status === 'failed') {
            message.error('Assistant request failed.');
            responseReceived = true;
          } else {
            await new Promise(resolve => setTimeout(resolve, 500));
          }
        }
      }
      setHistory(newHistory);
      setInput(''); // Limpiar el input después de enviar el mensaje
    } catch (error) {
      if (error instanceof OpenAI.APIError) {
        message.error(`Error while sending message: ${error.message}`);
      } else {
        message.error(`Error while sending message: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Maneja la tecla "Enter" para enviar el mensaje
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Previene que el "Enter" añada una nueva línea
      handleSendMessage(); // Llama a la función de enviar mensaje
    }
  };

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [history]);

  return (
    <Space direction="vertical" style={{ width: '100%', padding: '10px', marginBottom: '150px' }}>
      <Title level={2}>Hola, Qué prueba deseas crear hoy?</Title>
      {history.map((message, index) => (
        <Typography.Paragraph key={index} style={{ whiteSpace: 'pre-wrap' }}>
          <strong>{message.role === 'user' ? 'You' : 'Emma'}:</strong>
          <ReactMarkdown
            children={message.content}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={dark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                );
              }
            }}
          />
        </Typography.Paragraph>
      ))}
      <div ref={messagesEndRef} />
      <div style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        padding: '5px',
        boxShadow: '0 -5px 8px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '10px',
      }}>
        <div style={{ width: '100%' }}>
          <TextArea
            rows={4}
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Salúdame o dime algo para iniciar"
            style={{ width: '100%' }}
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button type="primary" onClick={handleSendMessage} loading={isLoading}>
            Enviar
          </Button>
          <span style={{ marginLeft: '10px' }}>{threadId}</span>
        </div>
      </div>
      {user && user.name && threadId && (
        <FloatButton
          icon={<span role="img" aria-label="help" style={{ fontSize: '24px' }}>?</span>}
          href={`https://wa.me/+573004526186?text=Hola%20soy%20${encodeURIComponent(user.name)},%20necesito%20calificar%20los%20examenes%20con%20el%20identificador%20${encodeURIComponent(threadId)}`}
          target="_blank"
          tooltip="Solicitar calificación de examenes"
          style={{
            bottom: 3,
            right: 20,
          }}
        />
      )}
    </Space>
  );
};

export default Home;
