import React, { useState, useEffect } from 'react';
import { List, Typography, Space, message, Input, Pagination, Spin, Button } from 'antd';
import { useAuthContext } from "../../context/AuthContext";
import OpenAI from 'openai';
import { getToken } from "../../helpers";
import { API } from "../../constant";

const { Title, Text } = Typography;
const { Search } = Input;

const History = () => {
  const { user } = useAuthContext();
  const [threads, setThreads] = useState([]); // Almacena los threads
  const [filteredThreads, setFilteredThreads] = useState([]); // Threads filtrados
  const [selectedThreadId, setSelectedThreadId] = useState(null); // ID del thread seleccionado
  const [messages, setMessages] = useState([]); // Almacena los mensajes del thread seleccionado
  const [loadingMessages, setLoadingMessages] = useState(false); // Estado para la carga perezosa
  const [currentPage, setCurrentPage] = useState(1); // Página actual para paginación
  const [totalThreads, setTotalThreads] = useState(0); // Total de threads para paginación
  const [pageSize] = useState(5); // Número de threads por página
  const [hasMoreMessages, setHasMoreMessages] = useState(true); // Indicar si hay más mensajes por cargar
  const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_KEY, dangerouslyAllowBrowser: true });

  // Obtener los threads del usuario autenticado con paginación
  useEffect(() => {
    const fetchThreads = async (page = 1) => {
      try {
        const response = await fetch(`${API}/open-ai-threads?filters[users_permissions_user][$eq]=${user.id}&pagination[page]=${page}&pagination[pageSize]=${pageSize}`, {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });
        if (!response.ok) {
          throw new Error('Error al obtener los threads');
        }
        const data = await response.json();
        setThreads(data.data); // Almacenar threads en el estado
        setFilteredThreads(data.data); // Inicialmente no hay filtro
        setTotalThreads(data.meta.pagination.total); // Guardar el total de threads para paginación
      } catch (error) {
        message.error(`Error al cargar threads: ${error.message}`);
      }
    };

    if (user) {
      fetchThreads(currentPage); // Cargar los threads en la página actual
    }
  }, [user, currentPage]);

  // Función para formatear la fecha usando las APIs nativas de Date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  // Manejar la búsqueda de threads
  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const filtered = threads.filter((thread) => 
        thread.attributes.id_value.toLowerCase().includes(searchValue)
    );
    setFilteredThreads(filtered);
  };

  // Manejar la selección de un thread y cargar mensajes con carga perezosa
  const handleSelectThread = async (threadId) => {
    setSelectedThreadId(threadId); // Actualizar el thread seleccionado
    setMessages([]); // Limpiar los mensajes anteriores
    setLoadingMessages(true); // Mostrar estado de carga
    setHasMoreMessages(true); // Resetear el estado de más mensajes por cargar

    try {
      const threadMessages = await openai.beta.threads.messages.list(threadId, { limit: 10 }); // Cargar solo 10 mensajes
      setMessages(threadMessages.data); // Almacenar mensajes en el estado
      if (threadMessages.data.length < 10) {
        setHasMoreMessages(false); // Si hay menos de 10 mensajes, desactivar más carga
      }
    } catch (error) {
      message.error(`Error al cargar mensajes: ${error.message}`);
    } finally {
      setLoadingMessages(false);
    }
  };

  // Cargar más mensajes cuando el usuario haga clic en el botón
  const loadMoreMessages = async () => {
    if (!selectedThreadId || loadingMessages || !hasMoreMessages) return;
    
    setLoadingMessages(true);
    try {
      const newMessages = await openai.beta.threads.messages.list(selectedThreadId, { limit: 10, after: messages[messages.length - 1]?.id });
      setMessages([...messages, ...newMessages.data]); // Añadir los nuevos mensajes
      if (newMessages.data.length < 10) {
        setHasMoreMessages(false); // Desactivar el botón si no hay más mensajes
      }
    } catch (error) {
      message.error(`Error al cargar más mensajes: ${error.message}`);
    } finally {
      setLoadingMessages(false);
    }
  };

  return (
    <div style={{ display: 'flex', width: '100%', height: '100vh' }}>
      {/* Panel Izquierdo (30%): Lista de Threads con Búsqueda y Paginación */}
      <div style={{ width: '30%', padding: '10px', borderRight: '1px solid #f0f0f0' }}>
        <Title level={4}>Historial de Conversaciones</Title>
        <Search placeholder="Buscar por thread" onSearch={handleSearch} style={{ marginBottom: 20 }} />
        <List
          bordered
          dataSource={filteredThreads}
          renderItem={(thread) => (
            <List.Item
              onClick={() => {
                handleSelectThread(thread.attributes.id_value);
              }}
            >
              <Text>{`Conversación ${thread.attributes.id_value} - ${formatDate(thread.attributes.createdAt)}`}</Text>
            </List.Item>
          )}
        />
        <Pagination
          current={currentPage}
          total={totalThreads}
          pageSize={pageSize}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginTop: 20 }}
        />
      </div>

      {/* Panel Derecho (66%): Mensajes del Thread Seleccionado */}
      <div style={{ width: '66%', padding: '10px', overflow: 'auto' }}>
        <Title level={4}>Mensajes</Title>
        {selectedThreadId ? (
          <List
            bordered
            dataSource={messages}
            renderItem={(msg) => (
              <List.Item>
                <Text>{`${msg.role}: ${msg.content[0]?.text?.value || ''}`}</Text>
              </List.Item>
            )}
          />
        ) : (
          <Text>Selecciona una conversación para ver los mensajes</Text>
        )}
        {loadingMessages && <Spin tip="Cargando más mensajes..." />}
        {/* Mostrar el botón para cargar más mensajes solo si hay más por cargar y se ha seleccionado una conversación */}
        {selectedThreadId && hasMoreMessages && !loadingMessages && (
          <Button onClick={loadMoreMessages} style={{ marginTop: 20 }}>
            Cargar más mensajes
          </Button>
        )}
      </div>
    </div>
  );
};

export default History;
