import { Menu, Dropdown, Button, Space } from "antd";
import React from "react";
import { MenuOutlined } from '@ant-design/icons';
import logo from '../../assets/logo-grey.png';
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { removeToken } from "../../helpers";

const AppHeader = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    navigate("/SignIn", { replace: true });
  };

  const menuItems = [
    { key: 'profile', label: <a href="/profile">Tu cuenta</a> },
    { key: 'home', label: <a href="/Home">Crear una prueba</a> },
    { key: 'history', label: <a href="/History">Historial</a> },
    { key: 'logout', label: <Button type="primary" onClick={handleLogout}>Logout</Button> },
  ];

  const guestMenuItems = [
    { key: 'signin', label: <a href="/SignIn">Ingresar</a> },
    { key: 'signup', label: <a href="/SignUp">Regístrate</a> },
  ];

  const menu = (
    <Menu items={user ? menuItems : guestMenuItems} />
  );

  return (
    <Space className="header_space" style={{ padding: 0, width: '100%', justifyContent: 'space-between' }}>
      <Button className="header_space_brand" href="/" type="link">
        <img src={logo} alt="Edtools Logo" style={{ width: 104, height: 'auto' }} />
      </Button>

      {/* Mostramos los botones en pantallas grandes */}
      <div className="desktop-menu">
        <Space className="auth_buttons">
          {user ? (
            <>
              <Button className="auth_button_login" href="/profile" type="link">
                Tu cuenta
              </Button>
              <Button className="auth_button_login" href="/Home" type="primary">
                Crear una prueba
              </Button>
              <Button className="auth_button_login" href="/History" type="primary">
                Historial
              </Button>

              <Button
                className="auth_button_signUp"
                type="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>
            </>
          ) : (
            <>
              <Button className="auth_button_login" href="/SignIn" type="link">
                Ingresar
              </Button>
              <Button
                className="auth_button_signUp"
                href="/SignUp"
                type="primary"
              >
                Regístrate
              </Button>
            </>
          )}
        </Space>
      </div>

      {/* Menu hamburguesa para pantallas pequeñas */}
      <div className="mobile-menu">
        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
          <Button icon={<MenuOutlined />} />
        </Dropdown>
      </div>
    </Space>
  );
};

export default AppHeader;
